import { Toast } from "vant"

export default {
    // Vue.js的插件应当有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象。
    install: function (Vue) {
        /**
         * 判断对象是否为空
         * @param {*} obj
         */
        Vue.config.globalProperties.$tools = {
            isNull: function (obj) {
                // 数组
                if (obj instanceof Array) {
                    return obj.length > 0 ? false : true;
                }
                // 对象
                if (obj instanceof Object) {
                    if (Object.keys(obj).length == 0) {
                        return true;
                    }
                    return false;
                }
                // String
                if (obj instanceof String || typeof obj == "string") {
                    let flag = false;
                    if (obj == "") {
                        flag = true;
                    }
                    return flag;
                }
                // 判断是否是null或者是undefined
                if (obj == null || typeof obj == undefined || obj === "") {
                    return true;
                } else {
                    return false;
                }
            },
            /**
             * 将金钱转成固定模式
             * ex:455646 -> 455,646.00
             * @param {*} value
             */
            moneyFormat: function (value, key) {
                let money = parseFloat(value);
                if (!value) {
                    return;
                }
                if (String(value).indexOf(".") == -1) {
                    money = money + ".00";
                    if (key) {
                        money = "";
                        return;
                    }
                }
                if (money && money != null) {
                    money = String(money);
                    var left = money.split(".")[0],
                        right = money.split(".")[1];
                    right = right
                        ? right.length >= 2
                            ? "." + right.substr(0, 2)
                            : "." + right + "0"
                        : ".00";
                    var temp = left
                        .split("")
                        .reverse()
                        .join("")
                        .match(/(\d{1,3})/g);

                    return (
                        (Number(money) < 0 ? "-" : "") +
                        temp.join(",").split("").reverse().join("") +
                        right
                    );
                } else if (money === 0) {
                    // 注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
                    return "0.00";
                } else {
                    return "";
                }
            },
            /**
             * 将金额数字转为中文
             * @param {*} n
             */
            moneyFormatChinese: function (value) {
                // 标记一个数是否为负数，如果是最终结果需要加个 负字
                let isNegative = false;
                if (!value || value == 0) {
                    return "零元整";
                }
                if (value < 0) {
                    value = Math.abs(value);
                    isNegative = true;
                }
                if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
                    return "";
                }
                var unit = "仟佰拾亿仟佰拾万仟佰拾元角分",
                    result = "";
                value += "00";
                var p = value.indexOf(".");
                if (p >= 0) {
                    value = value.substring(0, p) + value.substr(p + 1, 2);
                }
                unit = unit.substr(unit.length - value.length);
                for (var i = 0; i < value.length; i++) {
                    result +=
                        "零壹贰叁肆伍陆柒捌玖".charAt(value.charAt(i)) +
                        unit.charAt(i);
                }
                const resultStr = result
                    .replace(/零(仟|佰|拾|角)/g, "零")
                    .replace(/(零)+/g, "零")
                    .replace(/零(万|亿|元)/g, "$1")
                    .replace(/(亿)万|壹(拾)/g, "$1$2")
                    .replace(/^元零?|零分/g, "")
                    .replace(/元$/g, "元整");
                if (isNegative) {
                    return "负" + resultStr;
                } else {
                    return resultStr;
                }
            },

            // 判断表单参数，并给出提示值
            checkFormParams(arr) {
                if (arr.length > 0) {
                    for (let index = 0; index < arr.length; index++) {
                        const element = arr[index];
                        if (this.isNull(element.value)) {
                            Toast(element.msg);
                            return true;
                        }
                    }
                }
                return false;
            },
            //排重(单数组)
            distinct(arr) {
                arr = arr.sort();
                if (this.isNull(arr)) {
                    return [];
                }
                let result = [arr[0]];
                for (let i = 1, len = arr.length; i < len; i++) {
                    arr[i] !== arr[i - 1] && result.push(arr[i]);
                }
                return result;
            },
            //排重(数组对象)
            distinctObj(arr) {
                let result = [];
                var obj = {};
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        result.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return result;
            },
            /**
             * @description: 深拷贝
             * @param {*} target
             */
            deepClone(target) {
                // 定义一个变量
                let result;
                // 如果当前需要深拷贝的是一个对象的话
                if (typeof target === "object") {
                    // 如果是一个数组的话
                    if (Array.isArray(target)) {
                        result = []; // 将result赋值为一个数组，并且执行遍历
                        for (let i in target) {
                            // 递归克隆数组中的每一项
                            result.push(this.deepClone(target[i]));
                        }
                        // 判断如果当前的值是null的话；直接赋值为null
                    } else if (target === null) {
                        result = null;
                        // 判断如果当前的值是一个RegExp对象的话，直接赋值
                    } else if (target.constructor === RegExp) {
                        result = target;
                    } else {
                        // 否则是普通对象，直接for in循环，递归赋值对象的所有值
                        result = {};
                        for (let i in target) {
                            result[i] = this.deepClone(target[i]);
                        }
                    }
                    // 如果不是对象的话，就是基本数据类型，那么直接赋值
                } else {
                    result = target;
                }
                // 返回最终结果
                return result;
            },
            /**
             * @description: 获取特殊的时间
             */
            onGetSpecialDate(key) {
                const now = new Date(); //当前日期
                const nowDay = now.getDate(); //当天
                const nowMonth = now.getMonth(); //当前月
                const nowYear = now.getFullYear(); //当前年
                //本月的开始时间
                if (key == "currMonthFirst") {
                    const monthStartDate = new Date(nowYear, nowMonth, 1);
                    return Date.parse(monthStartDate);
                }
                //本月的结束时间
                if (key == "currMonthLast") {
                    const monthEndDate = new Date(nowYear, nowMonth + 1, 0);
                    return Date.parse(monthEndDate);
                }
                if (key == "today") {
                    const monthStartDate = new Date(
                        `${nowYear}/${nowMonth + 1}/${nowDay} 23:59:59`
                    );
                    return monthStartDate.getTime();
                }
                if (key == "todayZero") {
                    const monthStartDate = new Date(
                        `${nowYear}/${nowMonth + 1}/${nowDay} 00:00:00`
                    );
                    return monthStartDate.getTime();
                }
                if (key == "preMonth") {
                    return getPreMonth(3);
                }
            },
        };

        Vue.config.globalProperties.getJurisdiction = function (key) {
            const operateResourceMap = window.$storage.getItem(
                "roleresourcemap",
                1
            );
            const limitDat =
                operateResourceMap && operateResourceMap[this.$route.path];
            return limitDat && limitDat.indexOf(key) > -1 ? false : true;
        };
        /**
         * 友盟统计代码
         * category：事件类别，必填项，表示事件发生在谁身上，如“视频”、“小说”、“轮显层”等等。
         * action：事件操作，必填项，表示访客跟元素交互的行为动作，如"播放"、"收藏"、"翻层"等等。
         * label：事件标签，选填项，用于更详细的描述事件，从各个方面都可以，比如具体是哪个视频，哪部小说，翻到了第几层等等。
         * value：事件值，选填项，整数型，用于填写打分型事件的分值，加载时间型事件的时长，订单型事件的价格等等。
         */
        Vue.config.globalProperties.uMengStatistics = function (
            category,
            action,
            label
        ) {
            // window.collect_event.push(["_setAccount", ymID]);
            window.collect_event.push(["_setAccount", null]);
            window.collect_event.push(["_trackEvent", category, action, label]);
        };
    },
};
function getPreMonth(number) {
    return addDate(new Date(), -number);
}
function addDate(date, days) {
    var d = new Date(date);
    d.setMonth(d.getMonth() + days);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    var val = d.getFullYear() + "/" + month + "/" + day + " 00:00:00";
    return new Date(val).getTime();
}


// 防抖
export var debounce = function(func, wait = 500) {
    var timeout;
    return function() {
        var context = this;
        var args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function() {
            func.apply(context, args);
        }, wait);
    };
};

// 节流
export var throttle = function(func, wait = 500) {
    var previous = 0;
    return function() {
        var now = Date.now();
        if (now - previous > wait) {
            func.apply(this, arguments);
            previous = now;
        }
    };
}