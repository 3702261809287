const MAP_KEYS = {
    token: "_INVOICE_TOKEN_",
    curUser: "_INVOICE_CURUSER_",
    savePwd: "_INVOICE_SAVEPWD_",
    userphone: "_USER_PHONE_", // 登录账号
    navMenuName: "_nav_Menu_Name_", // 菜单按钮名字
    navs: "_INVOICE_NAV_",
    tags: "_INVOICE_TAGS_",
    routeQuery: "_ROUTE_QUERY_", // 跳转时带的路由参数
};
let manageStorage = {
    setItem: function (key, dat, type) {
        if (type) {
            localStorage.setItem(MAP_KEYS[key], JSON.stringify(dat));
        } else {
            sessionStorage.setItem(MAP_KEYS[key], JSON.stringify(dat));
        }
    },
    removeItem: function (key, type) {
        if (type) {
            localStorage.removeItem(MAP_KEYS[key]);
        } else {
            sessionStorage.removeItem(MAP_KEYS[key]);
        }
    },
    getItem: function (key, type) {
        let obj = null;
        if (type) {
            obj =
                localStorage.getItem(MAP_KEYS[key]) &&
                JSON.parse(localStorage.getItem(MAP_KEYS[key]));
        } else {
            obj =
                sessionStorage.getItem(MAP_KEYS[key]) &&
                JSON.parse(sessionStorage.getItem(MAP_KEYS[key]));
        }
        return obj;
    },
};
export default manageStorage;
