import axios from "./http";
import apis from "./apis";
import storge from "../storage";
import { Toast } from "vant";

// axios.request(config)
// axios.get(url[, config])
// axios.delete(url[, config])
// axios.head(url[, config])
// axios.post(url[, data[, config]])
// axios.put(url[, data[, config]])
// axios.patch(url[, data[, config]])

var depot = {};

var getHttpUrl = function (url) {
    let result = url;
    if (apis[url]) {
        result = apis[url];
    }
    return result;
};

var replaceUrlParams = function (url, config) {
    if (!url) return;
    var params = config.params || {};

    Object.keys(params).forEach(function (key) {
        var reg = new RegExp("{" + key + "}", "g");
        url = url.replace(reg, params[key]);
    });
    return url;
};
var handleConf = function (conf) {
    if (!conf.headers) {
        conf.headers = {};
    }
    if (!conf.isForm) {
        if (!conf.headers["content-type"]) {
            conf.headers["content-type"] = "application/json";
        }
        if (!conf.headers["token"]) {
            let token = storge.getItem("token", 1) || "";
            if (token) {
                conf.headers["token"] = token;
            }
        }
    } else {
        if (!conf.headers["token"]) {
            let token = storge.getItem("token", 1) || "";
            if (token) {
                conf.headers["token"] = token;
            }
        }
    }

    return conf;
};

var handleTip = function (config, err) {
    if (config.autoTips) return;
    if (err && err.msg) {
        Toast({
            message: err.msg,
            position: "bottom",
        });
    }
};

// }

depot.get = function ({ url, config = {} }) {
    config = handleConf(config) || {};
    if (!config["params"]) {
        config = {
            ...config,
            params: {
                clientType: "APP",
            },
        };
    } else {
        config["params"]["clientType"] = "APP";
    }
    let urlTpm = replaceUrlParams(getHttpUrl(url), config);
    return new Promise((resolve, reject) => {
        axios
            .get(urlTpm, config)
            .then((res) => {
                if (res.status === 200) {
                    let result = res.data;
                    if (result.code === 0) {
                        if (config.returnConf) {
                            let tpm = {
                                ...result.data,
                                $conf: res,
                            };
                            resolve(tpm);
                        } else {
                            resolve(result.data);
                        }
                    } else {
                        handleTip(config, result);
                        if (config.returnConf) {
                            let tpm = {
                                ...result,
                                $conf: res,
                            };
                            reject(tpm);
                        } else {
                            reject(result);
                        }
                    }
                }
            })
            .catch((error) => {
                handleTip(config, error);
                if (config.returnConf) {
                    let tpm = {
                        ...error,
                        $conf: error,
                    };
                    reject(tpm);
                } else {
                    reject(error);
                }
                console.log("请求错误：" + error);
            });
    });
};

depot.post = function ({ url, data, config = {} }) {
    config = handleConf(config) || {};
    data["clientType"] = "APP";
    let urlTpm = replaceUrlParams(getHttpUrl(url), config);
    return new Promise((resolve, reject) => {
        axios
            .post(urlTpm, data, config)
            .then((res) => {
                if (res.status === 200) {
                    let result = res.data;
                    if (result.code === 0) {
                        if (config.returnConf) {
                            let tpm = {
                                ...result.data,
                                $conf: res,
                            };
                            resolve(tpm);
                        } else {
                            resolve(result.data);
                        }
                    } else {
                        handleTip(config, result);
                        if (config.returnConf) {
                            let tpm = {
                                ...result,
                                $conf: res,
                            };
                            reject(tpm);
                        } else {
                            reject(result);
                        }
                    }
                }
            })
            .catch((error) => {
                handleTip(config, error);
                if (config.returnConf) {
                    let tpm = {
                        ...error,
                        $conf: error,
                    };
                    reject(tpm);
                } else {
                    reject(error);
                }
                // console.log(error);
            });
    });
};
depot.put = function ({ url, data, config = {} }) {
    config = handleConf(config) || {};
    let urlTpm = replaceUrlParams(getHttpUrl(url), config);
    return new Promise((resolve, reject) => {
        axios
            .put(urlTpm, data, config)
            .then((res) => {
                if (res.status === 200) {
                    let result = res.data;
                    if (result.code === 0) {
                        if (config.returnConf) {
                            let tpm = {
                                ...result.data,
                                $conf: res,
                            };
                            resolve(tpm);
                        } else {
                            resolve(result.data);
                        }
                    } else {
                        handleTip(config, result);
                        if (config.returnConf) {
                            let tpm = {
                                ...result,
                                $conf: res,
                            };
                            reject(tpm);
                        } else {
                            reject(result);
                        }
                    }
                }
            })
            .catch((error) => {
                handleTip(config, error);
                if (config.returnConf) {
                    let tpm = {
                        ...error,
                        $conf: error,
                    };
                    reject(tpm);
                } else {
                    reject(error);
                }
                console.log(error);
            });
    });
};
depot.delete = function ({ url, config = {} }) {
    config = handleConf(config) || {};
    let urlTpm = replaceUrlParams(getHttpUrl(url), config);
    return new Promise((resolve, reject) => {
        axios
            .delete(urlTpm, config)
            .then((res) => {
                if (res.status === 200) {
                    let result = res.data;
                    if (result.code === 0) {
                        if (config.returnConf) {
                            let tpm = {
                                ...result.data,
                                $conf: res,
                            };
                            resolve(tpm);
                        } else {
                            resolve(result.data);
                        }
                    } else {
                        handleTip(config, result);
                        if (config.returnConf) {
                            let tpm = {
                                ...result,
                                $conf: res,
                            };
                            reject(tpm);
                        } else {
                            reject(result);
                        }
                    }
                }
            })
            .catch((error) => {
                if (config.returnConf) {
                    let tpm = {
                        ...error,
                        $conf: error,
                    };
                    reject(tpm);
                } else {
                    reject(error);
                }
                console.log("请求错误：" + error);
            });
    });
};

export default () => {
    window.requestDat = depot;
};
