export const detailType = {
    salesman: 1,
    agent: 2,
    customer: 3,
}

export const roleType = {
    agent: "AGENT", // 代理商
    sale: "SALE", // 销售员
    broker: "BROKER", // 经纪人
}