import { createStore } from 'vuex'

import app from './modules/app'
import channel from './modules/channel'

import getters from './getters'
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
    plugins: [createPersistedState({ storage: window.localStorage })],
    modules: {
        app,
        channel
    },
    getters,
})

export default store
