import { createRouter, createWebHashHistory } from "vue-router";
import ROUNTER_ARR from "./routerParams";
import storage from "@/utils/storage";

var routes = createRouter({
    routes: ROUNTER_ARR,
    history: createWebHashHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0,
            };
        }
    },
});
var WHITE_ARR_LIST = [];
var getWhiteList = function (dat, isWhite) {
    dat.forEach((d) => {
        if (
            d.path &&
            d.path !== "/" &&
            d.path !== "*" &&
            (d.isWhite || isWhite)
        ) {
            WHITE_ARR_LIST.push(d.path);
        }
        if (d.children && d.children.length) {
            getWhiteList(d.children, d.isWhiteChildren);
        }
    });
};
getWhiteList(ROUNTER_ARR);

var MENUS_DATA = [];
var MENUS_OBJ = {};
var formatHasMenuDat = function (dat) {
    if (dat && dat.childList && dat.childList.length) {
        dat.childList.forEach((d) => {
            if (d.url) {
                MENUS_DATA.push(d.url);
                MENUS_OBJ[d.url] = d.name;
            }
            if (d.childList && d.childList.length) {
                formatHasMenuDat(d);
            }
        });
    }
};

var toRouter = function (path, companyResource, next) {
    MENUS_DATA = [];
    formatHasMenuDat(companyResource);
    // let menusArr = MENUS_DATA;
    MENUS_OBJ[path] && $storage.setItem("navMenuName", MENUS_OBJ[path]);
    // 资源不做限制
    if (WHITE_ARR_LIST.indexOf(path) > -1) {
        return next();
    }

    return next();

    // TODO 路由权限
    // if (menusArr.indexOf(path) === -1) {
    //     let toUrl = menusArr.length ? menusArr[0] : "/blankPages";
    //     if (toUrl) {
    //         return next({ path: toUrl });
    //     } else if (from.path === "/login") {
    //         sessionStorage.clear();
    //     }
    // } else {
    //     return next();
    // }
};

// function getRoleResourceMap(next) {
//     return requestDat
//         .get({
//             url: "/getRoleResourceMap",
//         })
//         .then(
//             (res) => {
//                 // 不用浏览器的标签页不共享sessionStorage，只有跳转过去的才共享，改用localstorage
//                 storage.setItem("roleresourcemap", res, 1);
//             },
//             () => {}
//         );
// }

function getCurrentOperateResource(clearCache = false) {
    if (!clearCache) {
        let navsList = storage.getItem("navs", 1);

        if (navsList && navsList.length) {
            return Promise.resolve(navsList);
        }
    }

    return requestDat
        .get({
            url: "/getCurrentResource",
            config: {
                params: {
                    from: "AGENT",
                },
            },
        })
        .then(
            (res) => {
                // 不用浏览器的标签页不共享sessionStorage，只有跳转过去的才共享，改用localstorage
                storage.setItem("navs", res, 1);
                return res;
            },
            () => {}
        );
}

// var tip = function (from) {
//     if (from.path === "/login") {
//         if (from.query.type === "login" || !from.query.type) {
//             // Vue.prototype.$toast("登录成功")
//             window.VueTips("登录成功");
//         } else if (from.query.type === "reg") {
//             // Vue.prototype.$toast("注册成功")
//             window.VueTips("注册成功");
//         }
//     }
// };

// 获取原型对象上的push函数
const originalPush = routes.push;
routes.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

routes.beforeEach(({ path }, from, next) => {
    let isLogin = storage.getItem("token", 1) || "";
    if (path.indexOf("middlePage") > -1) {
        isLogin = 1;
        return next();
    }
    if (path == "/text" || path == "/templateInvoice") {
        return toRouter(path, companyResource, next, from);
    }
    if (!isLogin && path !== "/login") {
        return next({ path: "/login" });
    }

    var companyResource = storage.getItem("navs", 1);
    // var companyResourceMap = storage.getItem("control")

    if (path !== "/login" && path !== "/enterprise" && !companyResource) {
        Promise.all([getCurrentOperateResource()]).then(
            (res) => {
                if (res) {
                    companyResource = storage.getItem("navs", 1);
                    if (companyResource) {
                        toRouter(path, companyResource, next, from);
                        // tip(from);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
    } else {
        return toRouter(path, companyResource, next, from);
    }
});

routes.onError((error) => {
    // console.log("error", error)
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = routes.history.pending.fullPath;
    try {
        if (isChunkLoadFailed) {
            let LoadingChunk = window.sessionStorage.getItem("LoadingChunk");
            if (LoadingChunk) {
                window.sessionStorage.setItem(
                    "LoadingChunk",
                    LoadingChunk - 0 + 1
                );
            } else {
                window.sessionStorage.setItem("LoadingChunk", 1);
            }
            if (LoadingChunk - 0 > 5) {
                window.sessionStorage.removeItem("LoadingChunk");
            }
            window.location.reload();
            window.sessionStorage.setItem("LoadingChunkPath", targetPath);
            const sa = window.sa;
            if (sa && sa.track) {
                sa.track("element_click", {
                    page_title: "LoadingChunk",
                    element_type: "view",
                    page_source: targetPath, // 当前路由
                    element_name: `刷新页面${LoadingChunk - 0}次`, // 尝试 打开次数
                });
            }
        }
    } catch (e) {
        console.log(e);
    }
});
export default routes;

export { getCurrentOperateResource, routes };
