import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { watch } from "vue";
import app from "@/modules/app";
export default {
  __name: 'App',
  setup(__props) {
    const {
      route
    } = app;
    watch(route, () => {
      document.title = route.meta.title || "代理商管理系统";
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};