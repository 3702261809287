import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_skeleton = _resolveComponent("van-skeleton");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_skeleton, _normalizeProps(_guardReactiveProps(_ctx.$props)), {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 16), _renderSlot(_ctx.$slots, "skeleton", {}, () => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.count, item => {
    return _openBlock(), _createBlock(_component_van_skeleton, _mergeProps({
      key: `good-skeleton-${item}`
    }, _ctx.$props), null, 16);
  }), 128))])], 64);
}