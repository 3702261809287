import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import storage from "@/utils/storage"
import { util } from "@/utils/common"
import depot from "@/utils/http"
import tools from "@/utils/tools";
import store from "@/store";
import VConsole from 'vconsole';
import VWave from 'v-wave'

import { Toast } from "vant"
import 'vant/es/toast/style';
import 'vant/es/dialog/style';

import skeleton from "@/components/skeleton.vue";
import pageList from "@/components/pageList";

import "@/assets/css/theme.less";
import "@/assets/css/global.less";

if (process.env.VUE_APP_NODE_ENV === 'development' ) {
    window.vConsole = new VConsole();
}

depot();

window.$storage = storage;

const app = createApp(App)

app.config.globalProperties.$util = util;

app.use(tools)
app.use(Toast)
app.use(store)
app.use(router)
app.use(VWave)

app.component(skeleton);
app.component(pageList);

app.mount('#app')
