const app = {
    state: {
        userInfo: null,
        isPass: null,
    },
    mutations: {
        UPDATE_ISPASS(state, data) {
            state.isPass = data;
        },
        UPDATE_USER(state, data) {
            state.userInfo = data;
        },
    },
    actions: {
        updateUser({ commit }, datas) {
            if (datas) {
                commit("UPDATE_USER", datas);
                return;
            }

            // 从接口获取
            return requestDat
                .get({
                    url: "/getCurrentUserInfo",
                    config: {
                        params: {
                            clientType: "APP",
                        },
                    },
                })
                .then((res) => {
                    commit("UPDATE_USER", res);
                    $storage.setItem("curUser", res || "", 1);

                    return res;
                });
        },
    },
};

export default app;
