import axios from 'axios'
import { date } from '@/utils/common'
// eslint-disable-next-line no-debugger

let biserver_tpml = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_API_BISERVER}`

if (process.env.LOCAL && window.location.origin !== biserver_tpml) {
    biserver_tpml = window.location.origin + '/invoiceBackendServer'
}

window.VUE_APP_API_BISERVER = biserver_tpml

const http = axios.create({
    headers: {},
    // baseURL: `${process.env.VUE_APP_PROTOCOL}://${hostP}`,
    baseURL: biserver_tpml,
    timeout: 180000,
    // withCredentials: true
});

// 添加请求拦截器
http.interceptors.request.use(
    (config) => {
        // 在发送请求之前做某事，比如说 设置token
        // config.headers['token'] = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMzA2NjY2ODg4OCIsInVpZCI6Ijg0MDY0MTIwNzA1NTE5NjE2IiwiYXV0aCI6IlNVUEVSX0FETUlOIiwiY29tcGFueUlkIjoiODQwNjQxMjA2NzYxNTk0ODgiLCJleHAiOjMwMTU2Mzg3NDcxMX0.zjhGNYXw-CQh1di8i78V0DDmut5C9RRXm-On0mk8k9jTersTC8kPkX0pTcldr7QLLv27OHN9UFtoT2ldKqI4_A';
        // 将所有开始时间调整到 00:00:00 所有结束时间调整到23:59:59
        if (config.method == "get" && config.params) {
            let params = config.params;
            const str = Object.keys(params).join(",");
            if (
                str.indexOf("Start") > -1 ||
                str.indexOf("Begin") > -1 ||
                str.indexOf("End") > -1
            ) {
                Object.keys(params).map((key) => {
                    if (
                        (key.indexOf("Begin") > -1 ||
                            key.indexOf("Start") > -1) &&
                        params[key]
                    ) {
                        const dateStr = date(params[key], "Y/M/D");
                        params[key] = new Date(dateStr + " 00:00:00").getTime();
                    }
                    if (key.indexOf("End") > -1 && params[key]) {
                        const dateStr = date(params[key], "Y/M/D");
                        params[key] = new Date(dateStr + " 23:59:59").getTime();
                    }
                });
            }
            config.params = params;
        }
        if (config.method == "post" && config.data) {
            let data = config.data;
            const str = Object.keys(data).join(",");
            if (
                str.indexOf("Start") > -1 ||
                str.indexOf("Begin") > -1 ||
                str.indexOf("End") > -1
            ) {
                Object.keys(data).map((key) => {
                    if (
                        (key.indexOf("Begin") > -1 ||
                            key.indexOf("Start") > -1) &&
                        data[key]
                    ) {
                        const dateStr = date(data[key], "Y/M/D");
                        data[key] = new Date(dateStr + " 00:00:00").getTime();
                    }
                    if (key.indexOf("End") > -1 && data[key]) {
                        const dateStr = date(data[key], "Y/M/D");
                        data[key] = new Date(dateStr + " 23:59:59").getTime();
                    }
                });
            }
            config.data = data;
        }
        return config;
    },
    (error) => {
        // 请求错误时做些事
        return Promise.reject(error);
    }
);

// 添加响应拦截器
http.interceptors.response.use(
    (response) => {
        // 对响应数据做些事
        if (response.status === 200) {
            if (response.data && response.data.code === 0) {
                //
            } else if (response.data.code === 500218) {
                if (window.location.href.indexOf("/#/login") === -1) {
                    window.location.href = "/#/login";
                    window.location.reload();
                }
            }
        }
        return response;
    },
    (error) => {
        if (error && error.response) {
            error.response.data = error.response.data || {};
            let map = {
                400: "错误请求",
                401: "无权访问",
                403: "拒绝访问",
                404: "请求错误，未找到该资源",
                405: "请求方法未允许",
                408: "请求超时",
                500: "服务器端出错",
                501: "网络未实现",
                502: "网络错误",
                503: "服务不可用",
                504: "网络超时",
                505: "http版本不支持该请求",
            };
            error.response.data.msg =
                map[error.response.status] || "连接服务器失败";
        }
        return Promise.reject(
            (error.response && error.response.data) || {
                msg: "网络出现问题，请稍后再试",
                error: error,
            }
        ); // 返回接口返回的错误信息
    }
);

export default http;
