import { roleType } from "@/assets/enums";

const getters = {
    sidebar: state => state.app.sidebar,
    infoDTO: state => {
        const userInfo = state.app.userInfo || {}

        return userInfo.agentDTO || userInfo.salerDTO
    },
    isAgent: state => state.app.userInfo?.roleType == roleType.agent,
    isSale: state => state.app.userInfo?.roleType == roleType.sale,
    isBroker: state => state.app.userInfo?.roleType == roleType.broker,
    agentCode: state => {
        try {
            
            const userInfo = state.app.userInfo
            
            if (['AGENT', 'BROKER'].includes(userInfo.roleType)) {
                return userInfo.agentDTO.agentCode;
            } else {
                // 销售员的
                return userInfo.salerDTO.agentCode;
            }

        } catch (error) {
            return '';
        }
    },
    salerCode: state => {
        try {
            
            const userInfo = state.app.userInfo

            if (['SALE'].includes(userInfo.roleType)) {
                return userInfo.salerDTO.salerCode;
            }

            return '';
        } catch (error) {
            return '';
        }
    },
}
export default getters