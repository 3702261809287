import { Skeleton } from "vant";
export default {
  name: "GoodsSkeleton",
  props: {
    ...Skeleton.props,
    itemCount: {
      type: Number,
      defualt: 0
    }
  },
  data() {
    return {
      count: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.itemCount) {
        this.count = this.itemCount - 1;
      } else {
        this.count = parseInt(this.$parent.$el.offsetHeight / 105 + 0.5) - 1;
      }
    });
  }
};