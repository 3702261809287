const apis = {
    "digital-agent-api" :{
        "/signIn": "/api/v1/agent/user/signin",
        "/updPwd": "/api/v1/agent/user/updatePassword",
        "/sendPhoneCode": "/api/v1/agent/user/sendPhoneCode",
        "/resetPwd": "/api/v1/agent/user/forgetPassword",
        "/getCaptcha": "/api/v1/agent/user/getCaptcha",
        "/getStsAccess": "/api/v1/aliyun/oss/stsAccessForWeb",                        // 上传获取临时token
        "/getCurrentResource": "/api/v1/agent/resource/currentOperateResource",
        "/getRoleResourceMap": "/api/v1/agent/resource/getRoleResourceMap",
        "/getCurrentOperateResource": "/api/v1/agent/resource/currentOperateResource",
        "/getInvoiceList": "/api/v1/agent/invoice/getInvoiceList",
        "/getAgentAuthTree": "/api/v1/agent/resource/agentAuthTree",
        "/getCompanyAuthTree": "/api/v1/agent/resource/companyAuthTree",
        "/getIndustryList": "/api/v1/invoice/company/industry/list",
        "/getAreaList": "/api/v1/agent/findAreaList",
        "/searchCompany": "/api/v1/invoice/company/tianyanchaSearch",                               // 输入公司名称自动关联税号
        "/homeStatistics": "/api/v1/workbench/statistics",
        "/getCurrentUserInfo": "/api/v1/agent/user/currentUserInfo",                        // 当前用户信息

        "/getMenuTree": "/api/v1/agent/resource/tree",                                      // 所有资源树
        "/getLevelList": "/api/v1/agent/level/list",                                        // 代理商等级列表
        "/createLevel": "/api/v1/agent/level/create",                                       // 添加代理商等级
        "/updateLevel": "/api/v1/agent/level/update",                                       // 编辑代理商等级
        "/deleteLevel": "/api/v1/agent/level/delete",                                       // 删除代理商等级
        "/activatedLevel": "/api/v1/agent/level/activated",                                 // 启用禁用代理商等级

        "/judgeCustomerPhone": "/api/v1/customer/phone/exist",                              // 判断客户手机号是否存在
        "/findAgentByPhone": "/api/v1/agent/findByPhone",                                   // 判断客户手机号是否已注册成代理商
        "/getCustomerList": "/api/v1/customer/pageList",                                    // 分页查询客户列表
        "/getApprovalCustomerList": "/api/v2/customer/pageList",                            // 分页查询费控客户列表
        "/getCustomerDetail": "/api/v1/customer/findById",                                  // 获取客户详情
        "/createCustomer": "/api/v1/customer/create",                                       // 添加客户
        "/updateCustomer": "/api/v1/customer/update",                                       // 编辑客户
        "/enableCustomer": "/api/v1/customer/enable",                                       // 启用/禁用客户
        "/removeCustomer": "/api/v1/customer/remove",                                       // 删除客户
        "/exportCustomer": "/api/v1/customer/export",                                       // 导出客户
        "/exportCustomerStatus": "/api/v1/customer/export/status",                          // 导出客户状态
        "/getCurrentAgentCertificateTime": "/api/v1/agent/getCurrentAgentCertificateTime",  // 获取代理商有效时间

        "/findProjectSettings": "/api/v1/agent/findProjectSettings",                        // 查询代理商项目设置
        "/saveProjectSettings": "/api/v1/agent/saveProjectSettings",                        // 保存代理商项目设置

        "/findRecord": "/api/v1/agent/findRecord",                                          // 获取记录列表(账户余额列表)
        "/findStock": "/api/v1/agent/findStock",                                            // 获取充值次数
        "/recharge": "/api/v1/agent/recharge",                                              // 充值--账户余额
        "/agentFullNumber": "/api/v1/agent/fullNumber",                                     // 充值--代理商给代理商充值
        "/fullNumber": "/api/v1/customer/fullNumber",                                       // 充值--代理商给客户充值
        "/fullRecordList": "/api/v1/customer/fullRecordList",                               // 充值记录

        "/getAgentDetail": "/api/v1/agent/findById",                                        // 获取记录列表(代理商管理)
        "/getCompanyDetail": "/api/v1/invoice/company/detail",                              // 获取详情
        "/getAgentList": "/api/v1/agent/pageList",                                          // 获取列表(代理商管理)
        "/createAgent": "/api/v1/agent/create",                                             // 添加代理商
        "/updateAgent": "/api/v1/agent/update",                                             // 编辑代理商
        "/enableAgent": "/api/v1/agent/enable",                                             // 启用/禁用代理商
        "/removeAgent": "/api/v1/agent/remove",                                             // 删除代理商
        "/getAgentOption": "/api/v1/agent/getAgentOption",                                  // 直属代理下拉列表
        
        "/exportRecord": "/api/v1/agent/exportRecord",                                      // 导出账户余额记录
        "/exportRecordStatus": "/api/v1/agent/exportRecord/status",                         // 导出账户余额记录状态
        "/getDirectlyList": "/api/v1/customer/getDirectlyList",                             // 获取当前代理下的直属代理商列表   
        "/changeAgent": "/api/v1/customer/changeAgent",                                     // 变更代理商

        "/setTagToAgent": "/api/v2/customer/saveTag",                                       // 设置客户标签
        "/delTagCustomer": "/api/v2/customer/delTag",                                       // 删除客户标签
        "/createTag": "/api/v1/agent/customer/tag/create",                                  // 添加客户标签
        "/deleteTag": "/api/v1/agent/customer/tag/delete",                                  // 删除客户标签
        "/tagList": "/api/v1/agent/customer/tag/list",                                      // 客户标签列表
        "/flowList": "/api/v1/divide/flow/pageList",                                        // 分页查询流水列表

        "/channerDetail": "/api/v1/agent/channel/setting/detail",                           // 渠道码详情

        "/getAccountDetail": "/api/v1/account/findByAgentId",                               // 根据代理商ID查询账户

        "/orderList": "/api/agent/v1/order/pageList",                                       // 我的账户列表
        "/findByAgentId": "/api/v1/account/findByAgentId",                                  // 查询代理商账户
        "/saveTag": "/api/v2/customer/saveTag",                                             // 批量设置客户标签
        "/delTag": "/api/v2/customer/delTag",                                               // 删除客户标签
        "/updateAvatar": "/api/v1/agent/user/updateAvatar",                                 // 修改头像(海报)
        "/channelList": "/api/v1/saler/channelList",                                        // 我的渠道列表
        "/updateAgentChannelPoster": "/api/v1/agent/updateChannelPoster",                   // 修改代理商海报
        "/updateSalerChannelPoster": "/api/v1/saler/updateChannelPoster",                   // 修改销售员海报
        "/salerCustomer": "/api/v1/saler/customerList",                                     // 获取销售员客户列表
        "/homeStatic": "/api/agent/v1/ranking/user/getSurvey",                              // 首页统计信息
        "/getAgentDivide": "/api/v1/divide/agent/role",                                     // 获取代理商分成规则
        "/getPosterPageList": "/api/agent/v1/poster/pageList",                              // 获取海报列表
        "/getSalerList": "/api/v1/saler/pageList",                                          // 获取销售员列表
        "/createSaler": "/api/v1/saler/create",                                             // 创建销售员
        "/updateSaler": "/api/v1/saler/update",                                             // 修改销售员
        "/getCustomerById": "/api/v2/customer/findById",                                    // 根据Id获取客户
        "/getSalerByName": "/api/v1/saler/findByName",                                      // 根据名称查询销售员
        "/changeCustomerSaler": "/api/v2/customer/changeSaler",                             // 变更销售员
        "/enableSaler": "/api/v1/saler/enable",                                             // 禁用、启用销售员
        "/salerDetail": "/api/v1/saler/findById",                                           // 根据id查询销售员信息
        "/homeTopStatic": "/api/agent/v1/ranking/user/getStatistics",                       // 首页上面统计
        "/homeAnalyse": "/api/agent/v1/ranking/user/analyse",                               // 首页数据分析
        "/analyseAgentA": "/api/agent/v1/ranking/user/analyse/AAgent",                      // 代理商转化分析(A级代理商)
        "/analyseAgentB": "/api/agent/v1/ranking/user/analyse/BAgent",                      // 代理商转化分析(B级代理商)
        "/analyseFission": "/api/agent/v1/ranking/user/analyse/fission",                    // 代理商转化分析(裂变)
        "/analyseSalesConver": "/api/agent/v1/ranking/user/analyse/salesConver",            // 代理商转化分析(销售员转化)
        "/findCompanyList": "/api/v2/customer/findCompanyList",                             // 搜索企业
        "/addCustomer": "/api/v2/customer/addCustomer",                                     // 添加客户
    },
    "digital-base": {
        "/base/login": "/api/v1/user/signIn", // 登录
        "/base/signOut": "/api/v1/user/signOut", // 退出登录
        "/base/updatePassword": "/api/v1/user/updatePassword", // 修改密码
        "/base/getCaptcha": "/api/v1/user/getCaptcha", // 获取图形验证码
        "/base/verifyCode": "/api/v1/user/verifyCode", // 校验图形验证码
    },
    "digital-core": {
        "/core/getAgentSettlePageList": "/agentSettle/v1/page", // 获取结算单分页
        "/core/getAgentSettleDetailById": "/agentSettle/v1/info/id", // 获取单个结算单信息
        "/core/getAgentSettleDetailList": "/agentSettleDetail/v1/page", // 获取结算单详情列表
        "/core/getAgentAccountInfo": "/channelAgentAccount/v1/account/info", // 获取账户信息
    }
}

const keys = Object.keys(apis);

const apiProxy = new Proxy(apis, {
    get(target, key) {
        for (let i = 0; i < keys.length; i++) {
            const preUrl = keys[i];
            
            if(target[preUrl][key]) {
                return `/${preUrl}${target[preUrl][key]}`
            }
        }
    }
})

export default apiProxy;
