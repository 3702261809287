var utiltpm = {};

function isType(type) {
    return function (el) {
        return Object.prototype.toString.call(el) === "[object " + type + "]";
    };
}
var isArray = isType("Array");
var isObject = isType("Object");
var isFunction = isType("Function");
var isString = isType("String");
var isNumber = isType("Number");

var isUndefined = function (obj) {
    return typeof obj === "undefined";
};

function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, {
                        [key]: {},
                    });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {
                    [key]: source[key],
                });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
utiltpm = {
    isArray: isArray,
    isObject: isObject,
    isFunction: isFunction,
    isString: isString,
    isUndefined: isUndefined,
    isNumber: isNumber,
    merge: mergeDeep,
};

function checkNumber(n, t) {
    n = n + "";
    n = n && n.replace(/\s+/g, "");
    var filter = t ? /^([1-9]\d*|0)?(\.(\d{1,2})?)?$/ : /^([1-9]\d*|0)?$/;
    return filter.test(n);
}
utiltpm.numberChange = function (val, num, fix2, int) {
    val = val + "";
    if (checkNumber(val, fix2)) {
        let hasDot = val.indexOf(".");
        if (num) {
            if (hasDot > -1) {
                let tpm = val.split(".");
                val = tpm[0].slice(0, num) + "." + tpm[1].slice(0, fix2);
            } else if (val.length > num) {
                val = val.slice(0, num);
            }
        }
        if (int && hasDot > -1) {
            val = val.slice(0, hasDot);
        }
    } else {
        let tpm = val.split("");
        for (let i = 0; i < tpm.length; i++) {
            if (tpm[i] !== ".") {
                tpm[i] = tpm[i].replace(/\D/g, "");
            }
        }
        val = tpm.join("");
        if (val.indexOf(".") > -1) {
            let tpm1 = val.split(".");
            let tpm2 = tpm1[1].substring(0, 2);
            val = tpm1[0] + "." + tpm2;
        }
    }
    return val;
};

let imgCache = {};
utiltpm.drawImageAsync = function (ctx, url, args, width, height, scale = 1) {
    return new Promise((resolve, reject) => {
        const tmpArg = [...args];

        const index = [0, 1, 2, 3];

        if (tmpArg.length > 6) {
            index[2] = 6;
            index[3] = 7;
        }

        index.forEach((ele) => {
            tmpArg[ele] = tmpArg[ele] * scale;
        });

        if (imgCache[url]) {
            ctx.drawImage(imgCache[url], ...tmpArg);
            resolve();
            return;
        }

        const img = new Image();
        img.src = url + "?time=" + new Date().valueOf();
        img.crossOrigin = "Anonymous";

        width && (img.width = width * scale);
        height && (img.height = height * scale);

        img.onload = function () {
            imgCache[url] = img;

            ctx.drawImage(img, ...tmpArg);
            resolve();
        };

        img.onerror = reject;
    });
};
// base64 转 Blob
function base64ToBlob(base64) {
    let arr = base64.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

utiltpm.download = function (url, filename) {
    console.log(filename);
    let save_link = document.createElement("a");
    // save_link.href = url;
    // save_link.download = filename;
    // save_link.target = "_blank";

    save_link.setAttribute("href", url);
    save_link.setAttribute("download", filename);
    save_link.setAttribute("target", "_blank");

    document.body.appendChild(save_link);

    let event = new MouseEvent("click");
    save_link.dispatchEvent(event);
    save_link.remove();
};

utiltpm.downloadCanvas = function (canvas, filename) {
    this.download(this.canvasToBlobUrl(canvas), filename);
};

utiltpm.canvasToBlobUrl = function (canvas) {
    let img_png_src = canvas.toDataURL("image/png"); //将canvas保存为图片
    const href = window.URL.createObjectURL(base64ToBlob(img_png_src));

    return href;
}

utiltpm.canvasToBase64 = function (canvas, filename, ty = 'png') {
    let type = ty.toLowerCase().replace(/jpg/i, 'jpeg');
    const r = type.match(/png|jpeg|bmp|gif/)[0];
    const imgType =  'image/' + r;

    let img_png_src = canvas.toDataURL("image/png");  //将canvas保存为图片
    let imgData = img_png_src.replace(imgType,'image/octet-stream');

    return imgData;
}

utiltpm.copyText = function (text) {
    let input = document.createElement("input");
    input.value = text;
    document.body.appendChild(input);
    input.select();

    const res = document.execCommand("Copy");

    document.body.removeChild(input);

    return res;
};

export var util = utiltpm;

export var date = function (value, format) {
    if (value) {
        format = format || "Y-M-D ";
        if (
            value.length > 4 &&
            value.indexOf("/") === -1 &&
            value.indexOf("-") === -1
        ) {
            value = Number(value);
        }
        if (!value) return "";
        var date = new Date(value);
        var Y = date.getFullYear();
        var M =
            date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1;
        var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var m =
            date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes();
        var s =
            date.getSeconds() < 10
                ? "0" + date.getSeconds()
                : date.getSeconds();
        var forma = format.split("");
        if (forma.length) {
            var dateVal = [];
            forma.forEach(function (f) {
                switch (f) {
                    case "Y":
                        dateVal.push(Y);
                        break;
                    case "M":
                        dateVal.push(M);
                        break;
                    case "D":
                        dateVal.push(D);
                        break;
                    case "h":
                        dateVal.push(h);
                        break;
                    case "m":
                        dateVal.push(m);
                        break;
                    case "s":
                        dateVal.push(s);
                        break;
                    default:
                        dateVal.push(f);
                        break;
                }
            });
            return dateVal.join("");
        }
        return "";
    } else {
        return "";
    }
};

export var REG = {
    url: /^(http|https):\/\/(\w+.){2}(\w+)/,
    mobile: /^((1)\d{10})$/,
    number: /^0|([1-9][0-9]*)$/,
    numberdot: /^([1-9]\d*|0)?(\.(\d{1,2})?)?$/,
    // eslint-disable-next-line no-useless-escape
    email: /^([a-zA-Z0-9_\.\-])+\@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
};
