import layout from "@/views/layout";

var ROUNTER_ARR = [
    {
        path: "/home",
        name: "home",
        component: layout,
        children: [
            {
                path: "/myInvite",
                name: "myInvite",
                component: () => import("@/views/myInvite"),
                meta: { title: "邀请" }
            },
            {
                path: "/userInfo",
                name: "userInfo",
                component: () => import("@/views/userInfo"),
                meta: { title: "我的" }
            },
            {
                path: "/channel",
                name: "channel",
                component: () => import("@/views/channel"),
                meta: { title: "渠道" }
            },
            {
                path: "/account",
                name: "account",
                component: () => import("@/views/account"),
                isWhite: true,
                meta: { title: "我的账户" }
            },
        ],
    },
    {
        path: "/channel/detail",
        name: "channelDetail",
        component: () => import("@/views/channel/detailView"),
        meta: { title: "客户详情" }
    },
    {
        path: "/salesman/add",
        name: "addSalesman",
        component: () => import("@/views/channel/salesman/addSalesman"),
        meta: { title: "添加销售员" }
    },
    {
        path: "/customer/add",
        name: "addCustomer",
        component: () => import("@/views/channel/customer/addCustomer"),
        meta: { title: "添加客户" }
    },
    {
        path: "/myInvite/changeBackground",
        name: "changeBackground",
        component: () => import("@/views/myInvite/changeBackground"),
        meta: { title: "添加客户" }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/loginView"),
        isWhite: true,
        meta: { title: "登录" }
    },
    {
        path: "/middlePage",
        name: "middlePage",
        component: () => import("@/views/login/middlePage"),
        isWhite: true,
        meta: { title: "登录" }
    },
    {
        path: "/",
        hidden: true,
        redirect: { path: "/login" },
    },
];
export default ROUNTER_ARR;
