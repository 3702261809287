const channel = {
    state: {
        detail: {},
        detailType: "",
        active: "",
    },
    mutations: {
        setDetail(state, { detail, type }) {
            state.detail = detail;
            type && (state.detailType = type);
        },
        setActive(state, active) {
            state.active = active;
        }
    },
    actions: {
        setChannelDetail({ commit }, payload) {
            commit("setDetail", payload)
        },
        setActive({ commit }, payload) {
            commit("setActive", payload);
        }
    }
}

export default channel;