import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
    get store () {
        return useStore();
    },
    get route () {
        return useRoute();
    },
    get router () {
        return useRouter();
    }
}